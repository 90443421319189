@import '../../../../scss/theme-bootstrap';

.gnav-links {
  @include breakpoint($landscape-up) {
    padding-top: 8px;
  }
  &__image {
    padding-bottom: 30px;
    @include breakpoint($landscape-up) {
      padding-bottom: $container-pad-small;
    }
  }
  &__image-img {
    width: 100%;
  }
  // Hidden for PC
  &__mobile-content {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
    .gnav-links__headline {
      @include swap_direction(padding, 25px 25px 25px 0);
      cursor: pointer;
      display: block;
      font-weight: 700;
      position: relative;
      text-decoration: none;
    }
    &-toggle {
      background: url('/sites/jomalone2/themes/jomalone2_base/img/icons/src/plus-v2.svg') 0 center no-repeat;
      background-size: contain;
      display: block;
      #{$rdirection}: $gnav-mobile-hpad--outer;
      height: $gnav-icon--sm;
      position: absolute;
      top: 25px;
      width: $gnav-icon--sm;
      .gnav-links__mobile-content[aria-expanded='true'] & {
        background: url('/sites/jomalone2/themes/jomalone2_base/img/icons/src/minus-v2.svg') 0 center no-repeat;
        background-size: contain;
      }
    }
    .gnav-links__content {
      display: block;
      height: auto;
      margin-right: $gnav-mobile-hpad--outer;
      padding-#{$ldirection}: $gnav-mobile-hpad--inner;
      transition: height 0.3s ease-in;
      &.gnav-links__content--open {
        padding-#{$ldirection}: 0;
      }
    }
    &[aria-expanded='false'] {
      .gnav-links__content {
        display: none;
      }
    }
  }
  // Hidden for mobile
  &__desktop-content {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &__headline {
    @include breakpoint($landscape-up) {
      min-height: $container-pad-landscape;
    }
    a {
      color: $color-black;
      font-family: $font--lars-bold;
      text-decoration: none;
    }
  }
  &__subheader,
  &__link {
    font-family: $font--lars;
  }
  &__subheader {
    padding-bottom: $container-pad-small;
    @include breakpoint($landscape-up) {
      font-weight: 700;
      min-height: 39px;
    }
    a {
      color: $color-black;
      @include breakpoint($landscape-up) {
        display: inline-block;
        text-decoration: none;
      }
    }
  }
  &__link {
    color: $color-black;
    line-height: get-line-height(12px, 17px);
    padding-bottom: 12px;
    a {
      color: $color-black;
      text-decoration: none;
      &:hover {
        color: $color-gnav-grey;
      }
    }
    &--bold {
      a {
        font-weight: 700;
        &:hover {
          color: $color-gnav-grey;
        }
      }
    }
  }
}
